export const initializeLiveChat = (brandConfig) => {
  const { $gtm, $feature, $t } = useNuxtApp()
  const { isDev } = useRuntimeConfig().public
  const livechatBaseConfig = $feature.configLivechat

  window.vflivechat = {}

  const vfLiveChat = new Proxy(window.vflivechat, {
    set(target, property, value) {
      target[property] = value
      return true
    }
  })

  const createPrivacyLabel = () => {
    const dateEl = document.createElement('span')
    dateEl.innerHTML = vfLiveChat.PrivacyContent
    const newA = document.createElement('a')
    newA.href = vfLiveChat.PrivacyURL
    newA.target = '_blank'
    newA.innerHTML = vfLiveChat.linktext
    dateEl.appendChild(newA)

    if (['de', 'nl_NL'].includes(vfLiveChat.language)) {
      const afterEl = document.createElement('span')
      afterEl.innerHTML = $t.livechat.acquainted
      dateEl.appendChild(afterEl)
    }

    const labelEl = document.createElement('label')

    Object.assign(labelEl.style, {
      position: 'absolute',
      bottom: '.5rem',
      left: '0',
      padding: '0 1.25rem'
    })

    labelEl.className = 'uiLabel-left form-element__label uiLabel'
    labelEl.appendChild(dateEl)

    return labelEl
  }

  const addPrivacyContent = () => {
    const emailInputWrapper = document.querySelector('.uiInputEmail')

    if (!emailInputWrapper) {
      console.warn('Live chat: Prechat email input wrapper not found')
      return
    }

    const privacyLabel = createPrivacyLabel()
    emailInputWrapper.appendChild(privacyLabel)
    window.vflivechatcontentshown = true
  }

  const handleChatDisplay = () => {
    if (!window.vflivechatcontentshown) {
      $gtm?.push('livechat.onAction', 'Displayed')
      addPrivacyContent()
    }
  }

  let isCustomStylingLocale = false

  const handleAgentAvailability = ({ isAgentAvailable }) => {
    if (isAgentAvailable || isCustomStylingLocale) {
      const chatElements = document.querySelectorAll('[data-chat]') as unknown as HTMLElement[]
      chatElements.forEach((el) => {
        el.style.removeProperty('display')
        el.removeAttribute('disabled')
        el.classList.remove('pointer-events-none')
      })
    }

    if (isAgentAvailable)
      $gtm?.push('livechat.onAction', 'Impression')
  }

  const clickButton = () => {
    $gtm?.push('livechat.onAction', 'Button Click', false)

    if (isCustomStylingLocale) {
      const styles = [
        { selector: '.uiInput.uiInputEmail.uiInput--default.uiInput--input', styles: 'display: none;' },
        { selector: '.sidebarHeader[embeddedService-chatHeader_chatHeader]', styles: 'background: #fc6b00;' }
      ]
      styles.forEach(({ selector, styles }) => useStyleTag(`${selector} { ${styles} }`))
    }
  }

  const initializeLiveChatEventTracking = () => {
    window.vflivechatcontentshown = false
    const eventHandlers = {
      afterMaximize: handleChatDisplay,
      onSettingsCallCompleted: handleAgentAvailability,
      onHelpButtonClick: clickButton,
      onChatRequestSuccess: () => {
        $gtm?.push('livechat.onAction', 'Started', false)
        $gtm?.push('livechat.onAction', isCustomStylingLocale ? 'Chatbot Engaged' : 'Agent Requested')
      },
      onChatTransferInitiated: () => $gtm?.push('livechat.onAction', 'Agent Transfer Completed'),
      onChatEndedByChasitor: () => $gtm?.push('livechat.onAction', 'Closed', false),
      afterDestroy: () => window.vflivechatcontentshown = false
    }

    Object.entries(eventHandlers).forEach(([eventName, handler]) =>
      window.embedded_svc.addEventHandler(eventName, handler))
  }

  const getExtraPrechatFormDetails = ({ brand, emailLabel, pageType, origin, language }) => [
    { label: emailLabel, transcriptFields: ['Email__c'] },
    { label: 'Brand', value: brand, transcriptFields: ['Brand__c'] },
    { label: 'Visited Page', value: pageType, transcriptFields: ['Visited_Page__c'] },
    { label: 'Chat Origin', value: origin, transcriptFields: ['Chat_Origin__c'] },
    { label: 'ChatLanguage', value: language, transcriptFields: ['Chat_Language__c'] }
  ]

  const getExtraPrechatInfo = (emailLabel: string) => [
    {
      entityFieldMaps: [
        { isExactMatch: true, fieldName: 'PersonEmail', doCreate: false, doFind: true, label: emailLabel },
        { isExactMatch: true, fieldName: 'Brand__c', doCreate: false, doFind: true, label: 'Brand' }
      ],
      entityName: 'Account',
      showOnCreate: true,
      saveToTranscript: 'AccountId'
    },
    {
      entityFieldMaps: [
        { doCreate: false, doFind: false, fieldName: 'LastName', isExactMatch: true, label: 'Last Name' },
        { doCreate: false, doFind: false, fieldName: 'FirstName', isExactMatch: true, label: 'First Name' }
      ],
      entityName: 'Contact',
      showOnCreate: false
    },
    {
      entityName: 'Case',
      showOnCreate: false,
      entityFieldMaps: [
        { doCreate: false, doFind: false, fieldName: 'LiveChat_Email__c', isExactMatch: true, label: emailLabel }
      ]
    }
  ]

  const configureEmbeddedSvc = ({ brand, language, displayMessage, emailLabel, pageType, origin, loadingText }) => {
    Object.assign(window.embedded_svc.settings, {
      smallCompanyLogoImgURL: brandConfig.logoUrl,
      avatarImgURL: brandConfig.avatarUrl,
      displayHelpButton: true,
      language,
      defaultMinimizedText: displayMessage,
      prepopulatedPrechatFields: { FirstName: 'Visitor', LastName: 'Chat' },
      extraPrechatFormDetails: getExtraPrechatFormDetails({ brand, emailLabel, pageType, origin, language }),
      extraPrechatInfo: getExtraPrechatInfo(emailLabel),
      loadingText,
      enabledFeatures: ['LiveAgent'],
      entryFeature: 'LiveAgent'
    })
  }

  const getInitSettings = () => ({
    baseLiveAgentContentURL: vfLiveChat.baseLiveAgentContentURL,
    deploymentId: vfLiveChat.deploymentId,
    buttonId: vfLiveChat.buttonId,
    baseLiveAgentURL: vfLiveChat.baseLiveAgentURL,
    eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I3O0000008ONUUA2_173e7370ddd',
    isOfflineSupportEnabled: false
  })

  const initESW = (gslbBaseURL) => {
    const { language, shopName, pageType, buttonId, emailLabel, loadingtext } = vfLiveChat
    const chatSettings = {
      brand: brandConfig.brand,
      language,
      origin: shopName,
      pageType,
      buttonId,
      displayMessage: isCustomStylingLocale ? 'Chat' : 'Live Chat',
      emailLabel,
      loadingText: loadingtext
    }

    configureEmbeddedSvc(chatSettings)

    window.embedded_svc.init(
      vfLiveChat.SFURL_1,
      vfLiveChat.SFURL_2,
      gslbBaseURL,
      vfLiveChat.orgId,
      'Embedded_Chat',
      getInitSettings()
    )
  }

  const updatePageType = (currentPageType: string, currentPageName: string) => {
    const pageTypes = {
      'checkout': 'Checkout page',
      'faq': 'FAQ page',
      'shopcart': 'CART page',
      'contact-us': 'Contact Us page'
    }

    vfLiveChat.pageType = pageTypes[currentPageName] || pageTypes[currentPageType] || vfLiveChat.pageType
  }

  const setLiveChatConfig = () => {
    const { buttonIds, ...config } = livechatBaseConfig
    const livechatConfig = {
      ...config,
      language: brandConfig.languageConfig[vfLiveChat.CountryLanguage],
      buttonId: buttonIds[vfLiveChat.CountryLanguage],
      shopName: vfLiveChat.CountryLanguage,
      PrivacyURL: `${useOrigin()}${useLocalisedRoute('/customer-service/privacy-policy.html')}`,
      loadingtext: $t.livechat.loading,
      emailLabel: $t.livechat.emailLabel,
      linktext: $t.livechat.privacyLinkText,
      PrivacyContent: $t.livechat.privacyLabel
    }

    Object.assign(window.vflivechat, livechatConfig)
    isCustomStylingLocale = brandConfig.customStyleLocales.includes(vfLiveChat.shopName)
  }
  const injectAndInitLiveChat = async () => {
    if (!window.embedded_svc) {
      try {
        await loadScript(vfLiveChat.js_path)
        initializeLiveChatEventTracking()
        initESW(null)
      }
      catch (err) {
        console.error('Failed to load LiveChat script:', err)
      }
    }
    else {
      initializeLiveChatEventTracking()
      initESW('https://service.force.com')
    }
  }

  const loadCss = () => {
    const cssPath = brandConfig.cssPath
    try {
      if (!document.querySelector(`link[href="${cssPath}"]`)) {
        useHead({
          link: () => [
            {
              rel: 'stylesheet',
              type: 'text/css',
              href: cssPath,
              once: true
            }
          ],
        })
      }
    }
    catch (err) {
      console.warn('failed to load livechat css', err)
    }
  }

  let timerId

  const setupLiveChat = () => {
    const loadPageDataEvent = window.dataLayer?.find((event) => event.event === 'loadPageData')
    const isCookieAccepted = hasCookieConsent(['required', 'performanceAndAnalytics', 'functional', 'targeting'])

    // Initialize chat only if cookies was accepted
    if (loadPageDataEvent && isCookieAccepted) {
      clearInterval(timerId)
      loadCss()
      const {
        pageName: currentPageName,
        pageCategory: currentPageType,
        countryLanguage: currentLang
      } = loadPageDataEvent.page
      // exception out of default values
      const currentCountry = loadPageDataEvent.page.countryCode.replace('GB', 'uk')
      Object.assign(vfLiveChat, {
        live: !isDev,
        Country: currentCountry,
        CountryLanguage: `${currentCountry?.toLowerCase()}-${currentLang?.toLowerCase()}`,
        pageType: currentPageType,
        vflivechatcontentshown: false
      })

      updatePageType(currentPageType, currentPageName)
      setLiveChatConfig()
      injectAndInitLiveChat().catch((err) => console.error('Failed to initialize LiveChat:', err))
    }
  }

  const initLiveChat = () => {
    if (livechatBaseConfig?.enabled)
      timerId = setInterval(setupLiveChat, 5000)
  }

  return {
    initLiveChat
  }
}
